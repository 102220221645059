import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardHeader, Table, Container, Row, Col } from "reactstrap";
import UnassignedProducts from "../components/Dashboard/UnassignedProducts.jsx";

import Header from "../components/Header.jsx";
import ChatComponent from "../components/AI/Chat.jsx";

function Index(props) {
  return (
    <>
      <Header></Header>
      <Container fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                      <FormattedMessage id="dashboard.unassigned_products.title" defaultMessage="Your unassigned products" />
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <UnassignedProducts />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="my-5 mb-xl-0" xl="6">
            <ChatComponent {...props} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Index;
