import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import { ProductProvider } from "../../context/ProductContext";
import ProductWizard from "./ProductWizard";
import SimpleProductTabs from "../../components/ProductCreation/Wizard/SimpleProductTabs";
import SelectedSeries from "../../components/ProductCreation/SelectedSeries";

function ProductRangeCreation(props) {
  const { id } = props.match.params;

  return (
    <ProductProvider urlPartName={"new-range"} isWizard={true} item={id}>
      <Header>
        <h1>
          <FormattedMessage id="products.product_range.title" defaultMessage="Create a new product range" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col>
            <SelectedSeries></SelectedSeries>
            <SimpleProductTabs {...props}></SimpleProductTabs>
            <ProductWizard {...props}></ProductWizard>
          </Col>
        </Row>
      </Container>
    </ProductProvider>
  );
}

export default ProductRangeCreation;
