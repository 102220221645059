import React, { useContext, useEffect } from "react";
import { setDefinitions } from "../products/ProductDefinitions";
import { getAllCurveGroupErrors, getErrors, getProductErrors, getCurveErrors, getGroupAndCurvesErrors } from "../products/ProductValidation";
import { useSessionStorage } from "../utils/useSessionStorage";
import { useService } from "./ServiceContext";

const ProductContext = React.createContext({});

const createNewProduct = () => {
  return {
    id: { value: 0, version: 0 },
    isReadOnly: false,
    activo: true,
    codModeloProductoGenerico_version: 0,
    conceptClassUnic: "",
    codModeloMotor: 0,
    curveGroups: [],
  };
};

const curveProperties = ["tamany", "diametro", "hubDiameter", "polos", "tipoPalas", "palas", "grados", "cuerpo"];

function ProductProvider(props) {
  const { id, series, version, isWizard, item, urlPartName } = props;
  const { productSpecificationAccessor } = useService();
  const isNewProduct = id === "new" || item === "create";
  const sessionKey = isWizard ? "Genesis.ProductWizard" : isNewProduct ? `Genesis.Product.${id}` : `Genesis.Product.${id}-${series}-${version}`;

  async function getSpecification(id, seriesId, version) {
    let product = await productSpecificationAccessor.find({ id, seriesId, version });

    product.codModeloProductoGenerico_version = product.id.version;
    product.existingCurve = true;

    if (product.curveGroups.length > 0 && product.curveGroups[0].curves.length > 0) {
      const refCurve = product.curveGroups[0].curves[0];
      curveProperties.forEach((f) => (product[f] = refCurve[f]));
      product.curveGroups.forEach((cg, i) => {
        cg.index = i;
        cg.codModeloDato_Version = cg.codModeloDatoVersion;
        cg.existingGrupoCurve = true;
        cg.curves.forEach((c, j) => {
          //c.index = j; ADDed to backend now
          c.existingCurve = true;
          // c.orden = c.index,
          // c.referenceProduct = c.principal == 1,
          // c.codModeloGrupoCurva = c.codModeloGrupoCurva
        });
      });
    }
    return product;
  }

  async function getProduct(id, seriesId, version) {
    const productSpecification = await getSpecification(id, seriesId, version);
    setProducts([productSpecification]);
  }

  async function cloneProduct(id, seriesId, version, preserveGroups, newSeries) {
    let clonedProduct = await getSpecification(id, seriesId, version);
    if (clonedProduct) {
      console.log(clonedProduct);
      setProducts([
        {
          ...clonedProduct,
          id: { value: 0 },
          isReadOnly: false,
          series: newSeries ? { ...newSeries } : clonedProduct.series,
          codSerieCurva: newSeries ? newSeries.codSerieCurva : clonedProduct.codSerieCurva,
          clone: true,
          originalModeloProductoGenerico: clonedProduct.modeloProductoGenerico,
          modeloProductoGenerico: "",
          productoGenericoComercial: "",
          curveGroups: preserveGroups
            ? clonedProduct.curveGroups.map((cg) => {
                return {
                  ...cg,
                  //modeloGrupoCurva: "",
                  curves: cg.curves.map((c) => {
                    return {
                      ...c,
                      //id: 0,
                      //modeloCurva: ""
                      existingCurve: true,
                    };
                  }),
                  //id: 0,
                  existingGrupoCurve: true,
                };
              })
            : [],
        },
      ]);
    }
  }

  useEffect(() => {
    const cached = window.sessionStorage.getItem(sessionKey);
    if (!isNewProduct && (!cached || JSON.parse(cached).length === 0)) {
      getProduct(id, series, version);
    }
  }, [id]);

  const [products, setProducts] = useSessionStorage(sessionKey, isNewProduct ? [createNewProduct()] : []);
  const product = () => (products?.length > 0 ? (item ? products[item] ?? {} : products[0]) : {});
  return <ProductContext.Provider value={[product, products, setProducts, cloneProduct, isWizard ?? false, urlPartName]} {...props} />;
}

function useProduct() {
  const context = useContext(ProductContext);

  if (context == null) {
    throw new Error("useProduct must be used within a ProductProvider.");
  }

  const {
    productTechnicalAttributesProvider,
    productCurveGroupAttributesProvider,
    productCurveAttributesProvider,
    productExistenceProvider,
    curveExistenceProvider,
    grupoCurveExistenceProvider,
  } = useService();
  const [product, products, setProducts, cloneProduct, isWizard, urlPartName] = context;
  const isNewProduct = !product().id?.value;

  function updateProduct(data, resetDirty) {
    setProducts(
      products.map((p) => {
        if (p.id.value === data.id.value) {
          return {
            ...data,
            isDirty: !resetDirty,
          };
        } else return p;
      })
    );
  }

  function updateAllProducts(key, value, fields, curveGroupFields, curveFields) {
    if (fields) {
      setProducts(
        products.map((p) => {
          return {
            ...p,
            ...fields,
            curveGroups: !curveGroupFields
              ? p.curveGroups
              : p.curveGroups.map((cg) => {
                  return {
                    ...cg,
                    ...curveGroupFields,
                    curves: !curveFields
                      ? cg.curves
                      : cg.curves.map((c) => {
                          return {
                            ...c,
                            ...curveFields,
                          };
                        }),
                  };
                }),
          };
        })
      );
    } else {
      setProducts(
        products.map((p) => {
          return { ...p, [key]: value };
        })
      );
    }
  }

  function updateProducts(data, resetDirty) {
    setProducts(
      data.map((p) => {
        return { ...p, isDirty: !resetDirty };
      })
    );
  }

  function newProduct(series) {
    setProducts([{ ...createNewProduct(), series: series }]);
  }

  async function applyDefinitions() {
    const p = await setDefinitions([...products], productTechnicalAttributesProvider, productCurveGroupAttributesProvider, productCurveAttributesProvider);
    updateProducts(p);
  }

  function checkProductValidity(product) {
    return getProductErrors(product, productTechnicalAttributesProvider, productExistenceProvider, false);
  }

  function checkCurveGroupValidity(product) {
    return getAllCurveGroupErrors(product, productCurveGroupAttributesProvider, productCurveAttributesProvider, curveExistenceProvider, grupoCurveExistenceProvider, false);
  }

  function checkCurveValidity(curve, group) {
    return getCurveErrors(curve, group, productCurveAttributesProvider, curveExistenceProvider, false);
  }

  function checkGroupValidity(group) {
    return getGroupAndCurvesErrors(group, productCurveGroupAttributesProvider, productCurveAttributesProvider, curveExistenceProvider, grupoCurveExistenceProvider, false);
  }

  async function checkValidity(product) {
    return await getErrors(
      product,
      productTechnicalAttributesProvider,
      productCurveGroupAttributesProvider,
      productCurveAttributesProvider,
      productExistenceProvider,
      curveExistenceProvider,
      grupoCurveExistenceProvider
    );
  }

  async function productsAllValid() {
    for (let p = 0; p < products.length; p++) {
      const product = products[p];
      const errors = await checkValidity(product);
      if (errors.hasErrors) return false;
    }
    return true;
  }

  function isDirty() {
    return products.some((p) => p.isDirty);
  }

  return {
    product: product(),
    products,
    updateProduct,
    updateProducts,
    updateAllProducts,
    cloneProduct,
    newProduct,
    isNewProduct,
    isWizard,
    applyDefinitions,
    checkValidity,
    checkProductValidity,
    checkCurveValidity,
    checkCurveGroupValidity,
    checkGroupValidity,
    productsAllValid,
    isDirty,
    urlPartName,
  };
}

export { ProductProvider, useProduct };
