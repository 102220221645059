import React from "react";
import SelectSeries from "../../components/ProductCreation/SelectSeries";
import ProductInformation from "../../components/ProductCreation/ProductInformation";
import ProductCurvesInformation from "../../components/ProductCreation/ProductCurvesInformation";
import { useService } from "../../context/ServiceContext";
import { Prompt, Route, Switch } from "react-router-dom";
import ProductSummary from "../../components/ProductCreation/ProductSummary";
import { useProduct } from "../../context/ProductContext";
import { useIntl } from "react-intl";

function ProductWizard(props) {
  const { history } = props;
  const { productTechnicalAttributesProvider, productCurveGroupAttributesProvider, productCurveAttributesProvider, productConceptsProvider } = useService();
  const { isWizard, product, isDirty, urlPartName } = useProduct();
  const intl = useIntl();
  const { id, series, version } = props.match.params;

  function createProgressItems() {
    let progressItems = {};
    const hasSeries = product.series && product.series.id > 0;
    const url_parts = !isWizard ? `product/${id}/${series}/${version}` : `${urlPartName}/${id}`;

    if (!isWizard && id === "new") {
      progressItems.series = {
        to: "/products/product/new/-1/0/select-series",
        description: intl.formatMessage({ id: "product_information.select_a_series", defaultMessage: "Select a series" }),
      };
    }

    progressItems.technical = {
      to: `/products/${url_parts}/technical-information`,
      disabled: !hasSeries,
      description: intl.formatMessage({ id: "product_information.define_product_information", defaultMessage: "Define product information" }),
    };

    progressItems.curves = {
      to: `/products/${url_parts}/curves-information`,
      disabled: !hasSeries,
      description: intl.formatMessage({ id: "product_information.define_curves_information", defaultMessage: "Define curves information" }),
    };

    if (!product.isReadOnly) {
      progressItems.finish = {
        to: `/products/${url_parts}/finish`,
        disabled: !hasSeries,
        description: intl.formatMessage({ id: "product_information.finish", defaultMessage: "Finish" }),
      };
    }

    return progressItems;
  }

  function unsavedCheck(params) {
    return !isDirty() || product.isReadOnly || params.pathname.includes("/products/") || !product.conceptClassUnic
      ? true
      : intl.formatMessage({ id: "app.unsavedchanges", defaultMessage: "Are you sure you want to continue? You may lose any unsaved changes." });
  }

  const progressItems = createProgressItems();

  return (
    <div className={isWizard ? "product-creation-wizard" : "product-creation"}>
      <Prompt message={unsavedCheck} />

      <Switch>
        {progressItems.series && (
          <Route
            path={progressItems.series.to}
            render={(props) => {
              return <SelectSeries onNext={() => history.push(progressItems.technical.to)} progressItems={progressItems} {...props}></SelectSeries>;
            }}
          />
        )}
        <Route
          path={progressItems.technical.to}
          render={(props) => {
            return (
              <ProductInformation
                progressItems={progressItems}
                productTechnicalAttributesProvider={productTechnicalAttributesProvider}
                productConceptsProvider={productConceptsProvider}
                onNext={() => history.push(progressItems.curves.to)}
                onPrevious={progressItems.series ? () => history.push(progressItems.series.to) : undefined}
                {...props}
              ></ProductInformation>
            );
          }}
        />
        <Route
          path={progressItems.curves.to}
          render={(props) => {
            return (
              <ProductCurvesInformation
                progressItems={progressItems}
                productCurveGroupAttributesProvider={productCurveGroupAttributesProvider}
                productCurveAttributesProvider={productCurveAttributesProvider}
                productConceptsProvider={productConceptsProvider}
                onNext={() => history.push(progressItems.finish.to)}
                onPrevious={() => history.push(progressItems.technical.to)}
                {...props}
              ></ProductCurvesInformation>
            );
          }}
        />
        {!product.isReadOnly && (
          <Route
            path={progressItems.finish.to}
            render={(props) => {
              return (
                <ProductSummary
                  progressItems={progressItems}
                  productConceptsProvider={productConceptsProvider}
                  onPrevious={() => history.push(progressItems.curves.to)}
                  {...props}
                ></ProductSummary>
              );
            }}
          />
        )}
      </Switch>
    </div>
  );
}

export default ProductWizard;
