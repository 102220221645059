import React, { useEffect, useRef, useState } from "react";
import { useService } from "../../context/ServiceContext";
import { Card, CardBody, CardFooter, CardHeader, Row } from "reactstrap";
import { useSessionStorage } from "../../utils/useSessionStorage";

const ChatComponent = (props) => {
  const { history } = props;
  const { aiConversationMessenger, aiConversationProcessor } = useService();
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useSessionStorage("Genesis.ChatHistory", []);

  const scrollContainer = useRef(null);
  const lastMessageRef = useRef(null);

  const sendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = {
      role: "user",
      content: [{ type: "text", text: input }],
    };

    const updatedConversation = [...conversation, newMessage];

    try {
      const assistantContent = await aiConversationMessenger.store({
        messages: updatedConversation,
      });

      const assistantMessage = {
        role: "assistant",
        content: [
          {
            type: "text",
            text: assistantContent.content,
          },
        ],
      };

      setConversation([...updatedConversation, assistantMessage]);
      setInput("");
    } catch (error) {
      alert("Error: " + error);
    }
  };

  const clearMessages = () => {
    setConversation([]);
  };

  const processAction = async (data) => {
    var products = await aiConversationProcessor.store(data);
    products.forEach((product) => {
      product.name = product.modeloProductoGenerico;
      product.codModeloProductoGenerico_version = 0;
    });
    window.sessionStorage.setItem("Genesis.ProductWizard", JSON.stringify(products));
    history.push("/products/new-range/0/technical-information");
  };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  return (
    <Card className="shadow">
      <CardHeader>
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Genesis AI</h3>
          </div>
        </Row>
      </CardHeader>
      {conversation.length > 0 && (
        <CardBody>
          <div style={{ height: 500, overflowY: "scroll" }} ref={scrollContainer}>
            {conversation.map((msg, index) => (
              <div className={`d-flex ${msg.role == "user" ? "justify-content-end" : ""}`} key={index} ref={index === conversation.length - 1 ? lastMessageRef : null}>
                <div className="w-75">
                  <Summary message={msg.content[0].text} role={msg.role} onProcess={processAction} />
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      )}
      <CardFooter>
        <textarea
          className="form-control"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !e.repeat) {
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        <button className="btn btn-outline-danger mt-2" onClick={sendMessage}>
          Send
        </button>
        <button className="btn btn-outline-dark mt-2" onClick={clearMessages}>
          Clear
        </button>
      </CardFooter>
    </Card>
  );
};

const Summary = ({ message, role, onProcess }) => {
  const intentData = message[0] == "{" ? JSON.parse(message) : null;
  //const { intent, data, reply, answer } = intentData;

  const intent = intentData?.intent;
  const data = intentData?.data;
  const reply = intentData?.reply;
  const answer = intentData?.answer;
  const modifiers = intentData?.data?.modifiers;

  function process() {
    onProcess(intentData);
  }

  return (
    <Card className={`mb-3 ${role == "user" ? "bg-secondary text-dark mx-2" : "bg-white text-dark"}`}>
      <CardBody>
        {intent == "CREATE_RANGE" ? (
          <>
            <h4>{reply}</h4>
            <ul>
              <li>
                <strong>New Name:</strong> {data.newName}
              </li>
              {modifiers && (
                <li>
                  <strong>Modifiers:</strong>
                  <ul>
                    {modifiers.typeOfMotor && (
                      <li>
                        <strong>Type of Motor:</strong> {modifiers.typeOfMotor}
                      </li>
                    )}
                    {modifiers.regulatedVoltages?.length > 0 && (
                      <li>
                        <strong>Regulated Voltages:</strong> {modifiers.regulatedVoltages.join(", ")}
                      </li>
                    )}
                    {modifiers.frequencies?.length > 0 && (
                      <li>
                        <strong>Frequencies:</strong> {modifiers.frequencies.join(", ")}
                      </li>
                    )}
                    {modifiers.erp && (
                      <li>
                        <strong>ERP:</strong> {modifiers.erp}
                      </li>
                    )}
                    {modifiers.sizesRange && (modifiers.sizesRange.min != null || modifiers.sizesRange.max != null) && (
                      <li>
                        <strong>Sizes Range:</strong> {modifiers.sizesRange.min || 0} - {modifiers.sizesRange.max}
                        {modifiers.sizesRange.exclude?.length > 0 && <> (Excluding {modifiers.sizesRange.exclude.join(", ")})</>}
                      </li>
                    )}
                    {modifiers.includeCurveGroups != null && (
                      <li>
                        <strong>Include Curve Groups:</strong> {modifiers.includeCurveGroups ? "Yes" : "No"}
                      </li>
                    )}
                    {modifiers.includeCurves != null && (
                      <li>
                        <strong>Include Curves:</strong> {modifiers.includeCurves ? "Yes" : "No"}
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </>
        ) : intent == "QUESTION" ? (
          <h4>{answer}</h4>
        ) : (
          <></>
        )}
        {intentData == null && <>{message}</>}
      </CardBody>
      {intent == "CREATE_RANGE" && (
        <CardFooter>
          <button className="btn btn-outline-danger" onClick={process}>
            Create range
          </button>
        </CardFooter>
      )}
    </Card>
  );
};

export default ChatComponent;
