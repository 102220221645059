import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import { useProduct } from "../../../context/ProductContext";
import { fireAlert } from "../../../utils/alerts";
import ErrorSummary from "../ErrorSummary";
import { FormattedMessage, useIntl } from "react-intl";

function SimpleProductTabs(props) {
  const { id } = props.match.params;
  const { products, checkValidity, urlPartName } = useProduct();

  return (
    <Nav tabs className="mb-3 card blur product-wizard-tabs">
      {products.map((p, i) => (
        <SimpleProductTab key={i} checkValidity={checkValidity} product={p} active={id == i} to={`/products/${urlPartName}/${i}/technical-information`}></SimpleProductTab>
      ))}
    </Nav>
  );
}

function SimpleProductTab({ product, active, to, checkValidity }) {
  const [errors, setErrors] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const check = async () => {
      const err = await checkValidity(product);
      setErrors(err);
      console.log("validate");
    };
    check();
  }, [product]);

  return (
    <NavItem>
      <NavLink className={active ? "active" : ""} tag={RRNavLink} to={to}>
        {errors ? <i className={`fas fa-${errors.hasErrors ? "exclamation-triangle" : "check"}`}></i> : <i className="fas fa-circle-notch fa-spin"></i>} {product.name}
      </NavLink>
      {errors?.hasErrors && (
        <button type="button" className="btn btn-link btn-sm" onClick={() => fireAlert(<ErrorSummary intl={intl} errors={errors}></ErrorSummary>, "Missing attributes", "error")}>
          <FormattedMessage id="product_wizard.show_errors" defaultMessage="show errors" />
        </button>
      )}
    </NavItem>
  );
}

export default SimpleProductTabs;
