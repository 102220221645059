import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import AlertWithIcon from "../AlertWithIcon";
import FormattedNumber from "../FormattedNumber";
import { orderBy } from "lodash";
function ErPCompliance(props) {
  const intl = useIntl();
  const { erPCompliance, hasRelatedErPKits, onOpenRelatedErPKitSelection, category } = props;
  const optimal = erPCompliance?.optimal;

  function getComplianceTextClass() {
    let text = "text-danger";

    const greenTarget = ["V2020", "V2026", "N2018"];
    const yellowTarget = ["V2015", "N2016"];

    if (erPCompliance.targets.filter((t) => erPCompliance.optimalEfficiency >= t.target && greenTarget.includes(t.name)).length > 0) {
      text = "text-success";
    } else if (erPCompliance.targets.filter((t) => erPCompliance.optimalEfficiency >= t.target && yellowTarget.includes(t.name)).length > 0) {
      text = "text-warning";
    }
    return "font-weight-bold " + text;
  }

  function getExceptionTextClass() {
    return "font-weight-bold " + (erPCompliance.isExempt ? "text-success" : "text-warning");
  }

  function getExemptionText() {
    return intl.formatMessage(erPCompliance.isExempt ? { id: "app.yes", defaultMessage: "Yes" } : { id: "app.no", defaultMessage: "No" });
  }

  return (
    <>
      <Card>
        <CardBody>
          {erPCompliance && (erPCompliance.warnings == null || erPCompliance.warnings.length == 0) ? (
            <>
              <>
                {!!erPCompliance.targets && (
                  <Row className="mb-4">
                    <Col>
                      <Card>
                        <CardHeader>
                          <FormattedMessage id="curves_generator.compliance_efficiency_data" defaultMessage="Compliance and efficiency data" />
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={6} xl={3}>
                              <Label>
                                <FormattedMessage id="curves_generator.maximum_efficiency" defaultMessage="Maximum efficiency" /> (%)
                              </Label>
                              <p className={`p-0 ${getComplianceTextClass()}`}>
                                <FormattedNumber
                                  value={erPCompliance.optimalEfficiency}
                                  options={{
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  }}
                                />
                              </p>
                            </Col>
                            <Col md={6} xl={3}>
                              <Label>
                                <FormattedMessage id="curves_generator.is_exempt" defaultMessage="Is exempt?" />
                              </Label>
                              <p className={`p-0 ${getExceptionTextClass()}`}>{getExemptionText()}</p>
                            </Col>
                            {category != "G16" && (
                              <Col md={6} xl={2} className="mt-md-4 mt-xl-0">
                                <Label>
                                  <FormattedMessage id="curves_generator.fmeg" defaultMessage="Actual FMEG 2015" />
                                </Label>
                                <p className="p-0">
                                  <FormattedNumber value={erPCompliance.fmeg} />
                                </p>
                              </Col>
                            )}

                            <Col md={6} xl={2} className="mt-md-4 mt-xl-0">
                              <Label>
                                <FormattedMessage id="curves_generator.fmeg2020" defaultMessage="Actual FMEG 2026" />
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={erPCompliance.fmeg2020} />
                              </p>
                            </Col>
                            <Col md={6} xl={2} className="mt-md-4 mt-xl-0">
                              <Label>
                                <FormattedMessage id="curves_generator.specific_ratio" defaultMessage="Specific ratio" />
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={erPCompliance.specificRatio} options={{ maximumFractionDigits: 6 }} />
                              </p>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            {orderBy(erPCompliance.targets, ["name"], ["asc"]).map((target) => (
                              <Col md={6} xl={3} key={target.name}>
                                <Label>
                                  <FormattedMessage id="curves_generator.target" defaultMessage="Target" /> {target.name} (%)
                                </Label>
                                <p className="p-0">
                                  <FormattedNumber value={target.target} />
                                </p>
                              </Col>
                            ))}

                            {orderBy(erPCompliance.targets, ["name"], ["asc"]).map((target) => (
                              <Col md={6} xl={2} key={target.name}>
                                <Label>
                                  <FormattedMessage id="curves_generator.fmeg_theoretical" defaultMessage="Theoretical FMEG" /> {target.name.replace("V","")}
                                </Label>
                                <p className="p-0">
                                  <FormattedNumber value={target.fmeg} />
                                </p>
                              </Col>
                            ))}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </>

              <>
                {!!erPCompliance.optimal && (
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <FormattedMessage id="curves_generator.optimal_point" defaultMessage="Optimal point" />
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={6} xl={3}>
                              <Label>
                                <FormattedMessage id="curves_generator.airflow" defaultMessage="Airflow" /> (m&sup3;/h)
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={optimal.airflow} />
                              </p>
                            </Col>
                            <Col md={6} xl={3}>
                              <Label>
                                <FormattedMessage id="curves_generator.static_pressure" defaultMessage="Static pressure" /> (Pa)
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={optimal.staticPressure} />
                              </p>
                            </Col>
                            <Col md={6} xl={3} className="mt-md-4 mt-xl-0">
                              <Label>
                                <FormattedMessage id="curves_generator.total_pressure" defaultMessage="Total pressure" /> (Pa)
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={optimal.totalPressure} />
                              </p>
                            </Col>
                            <Col md={6} xl={3} className="mt-md-4 mt-xl-0">
                              <Label>
                                <FormattedMessage id="curves_generator.current" defaultMessage="Current" /> (A)
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={optimal.electricalCurrent} />
                              </p>
                            </Col>
                          </Row>

                          <Row className="mt-4">
                            <Col md={6} xl={3}>
                              <Label>
                                <FormattedMessage id="curves_generator.input_power" defaultMessage="Input power" /> (W)
                              </Label>
                              <p className="p-0">
                                <FormattedNumber value={optimal.inputPower} />
                              </p>
                            </Col>
                            <Col md={6} xl={3}>
                              <FormGroup className="mb-0">
                                <Label>
                                  <FormattedMessage id="curves_generator.speed" defaultMessage="Speed" /> (rpm)
                                </Label>
                                <p className="p-0">
                                  <FormattedNumber value={optimal.speed} />
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <AlertWithIcon className="alert-warning" icon={<i className="fas fa-exclamation-triangle"></i>}>
                    <FormattedMessage id="curves_generator.no_erp_compliance_data" defaultMessage="There is currently no ErP compliance data." />
                  </AlertWithIcon>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
        {hasRelatedErPKits && (
          <CardFooter>
            <div className="d-flex justify-content-end px-1">
              <Button onClick={onOpenRelatedErPKitSelection}>
                <span>
                  <i className="fas fa-eye"></i> <FormattedMessage id="curves_generator.view_related_erp_kits" defaultMessage="View related ErP kits" />
                </span>
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    </>
  );
}

export default ErPCompliance;
